<template>
  <div>
    <mdb-container class="bg-dark d-md-none p-0 text-light">
      <mdb-navbar color="elegant" :toggler="false" position="top">
        <mdb-navbar-brand href="javascript:;" class="text-light">
          Nolitrak
        </mdb-navbar-brand>
        <mdb-icon icon="sync" class="light-ic float-right" @click.native="loadAircrafts" v-if="!isloading" />
        <mdb-spinner small v-else />
      </mdb-navbar>
      <mdb-container class="pt-5">
        <mdb-row class="pt-3">
          <mdb-col>
            <h2>Appareils</h2>
          </mdb-col>
        </mdb-row>
        <mdb-card class="card-dark">
          <mdb-card-body>
            <mdb-tbl sm>
              <mdb-tbl-body class="text-light">
                <tr>
                  <td colspan="2" class="align-middle">
                    <span class="float-left font-weight-bold">
                      EnRoute seulement
                    </span>
                  </td>
                  <td colspan="2" class="align-middle">
                    <mdb-switch class="float-right" onLabel="" offLabel="" v-model="onlyEnRoute" />
                  </td>
                </tr>
                <tr v-for="aircraft in aircraftswithfilters" v-bind:key="aircraft.call_sign" v-on:click="aircraftDetails(aircraft)">
                  <td>
                    <img :src="`https://storage.googleapis.com/owg-public/go.owg.com/prod/${aircraft.webimage}`" style="width: 60px;">
                  </td>
                  <td class="align-middle">
                    {{aircraft.call_sign}}
                  </td>
                  <td class="align-middle">
                    {{aircraft.posntypedescription}}
                  </td>
                  <td class="align-middle">
                    <mdb-icon icon="chevron-right" />
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-card-body>
        </mdb-card>
      </mdb-container>
    </mdb-container>
    <mdb-container class="d-none d-md-block text-center">
      <h1 class="light-ic pt-5">Work only on mobile!</h1>
    </mdb-container>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbTbl,
  mdbTblBody,
  mdbSwitch,
  mdbSpinner,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'nolitrakroot',
  data() {
    return {
      error: null,
      onlyEnRoute: true,
      aircrafts: [],
      isloading: true,
    };
  },
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbTbl,
    mdbTblBody,
    mdbSwitch,
    mdbSpinner,
  },
  mounted() {
    this.initAddToHomescreen();
    this.loadAircrafts();
  },
  computed: {
    aircraftswithfilters() {
      if (this.onlyEnRoute) {
        return this.aircrafts.filter((value) => value.speed > 0);
      }
      return this.aircrafts;
    },
  },
  methods: {
    initAddToHomescreen() {
      const logopath = '/public/images/nolinorlogo.jpg';
      const link = document.createElement('link');
      link.rel = 'apple-touch-icon';
      link.href = logopath;
      document.head.appendChild(link);
      const meta2 = document.createElement('meta');
      meta2.name = 'apple-mobile-web-app-title';
      meta2.content = 'NoliTrak';
      document.head.appendChild(meta2);
    },
    loadAircrafts() {
      this.isloading = true;
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/iosnolitrak/aircrafts-list').then((response) => {
        self.aircrafts = response.data;
        window.setTimeout(() => { self.isloading = false; }, 250);
      });
    },
    aircraftDetails(aircraft) {
      this.$router.push({ name: 'NolitrakDetails', params: { callsign: aircraft.call_sign } });
    },
  },
};
</script>
<style>
  body {
    background-color: #212121;
  }
  .card-dark{
    background-color: #2E2E2E;
  }
  td {
    border-top-color: #212121!important;
  }
</style>
