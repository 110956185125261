<template>
  <div>
    <mdb-container class="bg-dark d-md-none p-0 text-light">
      <mdb-navbar color="elegant" :toggler="false" position="top">
        <mdb-navbar-brand href="javascript:;" class="text-light" @click.native="$router.push({ name: 'NolitrakRoot' });">
          <mdb-icon icon="chevron-left" class="pr-2" /> {{$route.params.callsign}}
        </mdb-navbar-brand>
        <div v-if="false">
          <mdb-icon icon="sync" class="light-ic float-right" @click.native="loadAircrafts" v-if="!isloading" />
          <mdb-spinner small v-else />
        </div>
      </mdb-navbar>
      <mdb-container class="pt-5">
        <mdb-row class="grey lighten-2" >
          <mdb-col class="pt-2 px-0">
            <mdb-alert color="danger" v-if="error" class="m-2">{{error}}</mdb-alert>
            <div id="gmap" class="w-auto" style="height: 400px;" v-else></div>
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="!error">
          <mdb-col class="pl-2">
            <img :src="`https://storage.googleapis.com/owg-public/go.owg.com/prod/${image}`" class="z-depth-1 rounded-circle img-thumbnail" style="position: relative; top:-70px; height: 125px;z-index: 10;" alt="Responsive image">
          </mdb-col>
        </mdb-row>
        <div style="top: -100px;position:relative;" v-if="!error">
          <mdb-card class="card-dark mt-3">
            <mdb-card-body>
              <mdb-tbl sm v-if="aircraftPositions.length">
                <mdb-tbl-body class="text-light">
                  <tr>
                    <td class="font-weight-bold">Status</td>
                    <td class="text-right">{{currentStatus}}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Speed</td>
                    <td class="text-right">{{aircraftPositions[aircraftPositions.length - 1].speed}}</td>
                  </tr>
                  <tr v-if="aircraftAltitudes.length">
                    <td class="font-weight-bold">Altitude</td>
                    <td class="text-right">{{aircraftAltitudes[aircraftAltitudes.length - 1]}}</td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
          <mdb-row class="pt-4">
            <mdb-col>
              <h2>Altitude</h2>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col>
              <mdb-line-chart
                :data="lineChartData"
                :options="lineChartOptions"
                :width="600"
                :height="300"
                v-if="!isloading"
              ></mdb-line-chart>
            </mdb-col>
          </mdb-row>
        </div>
      </mdb-container>
    </mdb-container>
    <mdb-container class="d-none d-md-block text-center">
      <h1 class="light-ic pt-5">Work only on mobile!</h1>
    </mdb-container>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbSpinner,
  mdbAlert,
  mdbTbl,
  mdbTblBody,
  mdbLineChart,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'nolitrakdetails',
  data() {
    return {
      error: null,
      isloading: true,
      gmap: null,
      currentAircraftPosition: null,
      currentStatus: '',
      flightPath: [],
      aircraftPositions: [],
      aircraftAltitudes: [],
      image: '',
      lineChartData: {
        labels: ['', '', '', '', ''],
        datasets: [
          {
            label: 'Altitude',
            backgroundColor: 'rgba(170, 0, 255, 0.1)',
            borderColor: 'rgba(170, 0, 255, 1)',
            borderWidth: 0.7,
            data: [65, 1, 1, 81, 10, 55, 40],
            pointRadius: 0,
          },
        ],
      },
      lineChartOptions: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
        },
      },
    };
  },
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbSpinner,
    mdbAlert,
    mdbTbl,
    mdbTblBody,
    mdbLineChart,
  },
  mounted() {
    this.getAircraftInfos();
  },

  methods: {
    getAircraftInfos() {
      this.isloading = true;
      const self = this;
      const api = new Apicall();
      api.call('GET', `api/iosnolitrak/aircraft-current-flight?callsign=${this.$route.params.callsign}`).then((response) => {
        self.aircraftPositions = response.data.positions;
        self.aircraftAltitudes = response.data.altitudes;
        self.loadAltitudeDateForChart();
        self.currentStatus = response.data.status;
        self.image = response.data.image;
        if (!self.gmap) {
          self.loadMap();
        } else {
          self.updateMap();
        }
        this.isloading = false;
      });
    },
    loadAltitudeDateForChart() {
      this.lineChartData.labels = [];
      this.lineChartData.datasets[0].data = [];
      for (let i = 0; i < this.aircraftAltitudes.length; i += 1) {
        this.lineChartData.labels.push('');
        this.lineChartData.datasets[0].data.push(this.aircraftAltitudes[i]);
      }
    },
    loadMap() {
      if (this.aircraftPositions.length) {
        this.gmap = new window.google.maps.Map(document.getElementById('gmap'), {
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeId: 'satellite',
          zoom: 5,
          maxZoom: 15,
          center: {
            lat: this.aircraftPositions[this.aircraftPositions.length - 1].latitude,
            lng: this.aircraftPositions[this.aircraftPositions.length - 1].longitude,
          },
        });
        this.currentAircraftPosition = new window.google.maps.Marker({
          position: {
            lat: this.aircraftPositions[this.aircraftPositions.length - 1].latitude,
            lng: this.aircraftPositions[this.aircraftPositions.length - 1].longitude,
          },
          icon: this.generateMarker(this.aircraftPositions[this.aircraftPositions.length - 1].track),
          title: this.$route.params.callsign,
        });
        this.currentAircraftPosition.setMap(this.gmap);
        this.setFlightPath();
      } else {
        this.error = 'Unknown aircraft position';
      }
    },
    generateMarker(rot = 45) {
      return {
        path: 'M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9 c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45 c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5 l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57 c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75L16.63,105.75z',
        fillColor: 'black',
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: rot - 45,
        scale: 0.2,
        anchor: new window.google.maps.Point(65, 60),
      };
    },
    setFlightPath() {
      this.flightPath = null;
      const flightPathCoords = [];
      for (let i = 0; i < this.aircraftPositions.length; i += 1) {
        flightPathCoords.push({
          lat: this.aircraftPositions[i].latitude,
          lng: this.aircraftPositions[i].longitude,
        });
      }
      this.flightPath = new window.google.maps.Polyline({
        path: flightPathCoords,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.flightPath.setMap(this.gmap);
    },
    updateMap() {
      console.log('on update la map avec les nouvelles données');
    },
  },
};
</script>
<style>
body {
  background-color: #212121;
}
.card-dark{
  background-color: #2E2E2E;
}
td {
  border-top-color: #212121!important;
}
</style>
